import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
    <div className='app__wrapper_info'>
      <SubHeading title='Chase the new flavor' />
      <h1 className='app__header-h1'>The Key to Fine Dining</h1>
      <p className='p__opensans' style={{ margin: '2rem 0' }}>
        Fine dining is a style of restaurant service that typically offers
        high-quality, gourmet food, with an emphasis on presentation, technique,
        and service. The atmosphere of a fine dining establishment is often
        formal and elegant, with upscale decor and dress codes. Menus may
        feature elaborate dishes made with premium ingredients, and courses may
        be served in a specific order, such as appetizer, entree, and dessert.
        The service in fine dining restaurants is typically attentive and
        personalized, with servers providing detailed descriptions of dishes and
        recommending wine pairings. Overall, fine dining is an experience
        designed to provide a luxurious and memorable culinary experience.
      </p>
      <button type='button' className='custom__button'>
        Explore Menu
      </button>
    </div>
    <div className='app__wrapper_img'>
      <img src={images.welcome} alt='header_img' />
    </div>
  </div>
);

export default Header;
